import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { GlobalProvider } from 'components/common/GlobalContext'
import { Loader } from 'layouts/Loading'
import DefaultLayout from 'layouts/Default'
import { graphql, useStaticQuery } from 'gatsby'
import HeroWithBg from 'components/common/HeroWithBg'
import WISProduct from 'components/pg-wis/WISProduct'

const App = ({ pg, location }) => {
  const { isAuthenticated } = useAuth0()

  const params = new URLSearchParams(location.search)

  if (params.has('code')) {
    // log in users
    console.log({isAuthenticated})
  }

  return <>
    <HeroWithBg {...pg.hero} />
    {pg.products?.map((product, key) => (
      <WISProduct {...product} key={key} />
    ))}
  </>
}

const WISPg = props => {
  const { sanityWisPg: pg } = useStaticQuery(query)
  const { isLoading } = useAuth0()

  return isLoading ? <Loader/> :
    <DefaultLayout {...pg.meta}>
      <GlobalProvider>
        <App pg={pg} {...props} />
      </GlobalProvider>
    </DefaultLayout>
}

export default WISPg

const query = graphql`query WISPg {
  sanityWisPg {
    hero { ...hero }
    products {
      title
      _rawDescription
      ctas { ...cta }
      advisor {
        name
        contact { tel email }
      }
      image { asset { gatsbyImageData(placeholder: NONE) } }
    }
    seo {
      title
      description
    }
  }
}`
