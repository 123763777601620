import * as React from 'react'
import * as css from './WISProduct.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SanityBlock from 'components/common/SanityBlock'
import Cta from 'components/common/CTA'
import { FaPhone } from 'react-icons/fa'
import { IoMdPerson, IoIosMail } from 'react-icons/io'

const WISProduct = ({ title, _rawDescription, ctas, advisor, image }) => (
  <section className={`${css.root} container padding`}>
    <header className={`${css.header} richtext`}>
      <h2 className="h2">{title}</h2>
      {image && (
        <GatsbyImage image={getImage(image.asset)} alt={title || ''} />
      )}
    </header>

    <div className={css.grid}>
      <div className={css.desc}>
        <SanityBlock body={_rawDescription} />
      </div>

      <nav className="richtext">
        {ctas?.map((cta, key) => (
          <Cta className="full" {...cta} key={key} />
        ))}
      </nav>

      <div className="richtext">
        <h3 className="h3">Contact</h3>
        <dl className={css.advisor}>
          <dt><IoMdPerson /></dt>
          <dd>{advisor.name}</dd>

          <dt><FaPhone /></dt>
          <dd>
            <a className="link" href={`tel:${advisor.contact.tel}`}>
              {advisor.contact.tel}
            </a>
          </dd>

          <dt><IoIosMail /></dt>
          <dd>
            <a className="link" href={`mailto:${advisor.contact.email}`}>
              {advisor.contact.email}
            </a>
          </dd>
        </dl>
      </div>
    </div>
  </section>
)

export default WISProduct
